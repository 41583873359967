import React, { useEffect, useState } from 'react'
import {
  getFirestore, doc, collection, getDocs, query, where, limit, arrayRemove, arrayUnion,
  orderBy, deleteDoc, getCountFromServer, startAt, setDoc, getDoc, onSnapshot, startAfter, collectionGroup,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL, listAll, deleteObject, uploadBytes, } from "firebase/storage";
import HeaderImage from "../../DevAssets/Images/Construction.jpg"
import { useNavigate } from 'react-router-dom';
import coverImage from './cover.jpg'
import Swal from 'sweetalert2';
const Index = () => {
    let navigate = useNavigate();
    const [writeUps, setWriteUps] = useState([]);
    const [loadCount, setLoadCount] = useState(6);
    const [loading, setLoading] = useState(false);
    const [totalWriteUpsCount, setTotalWriteUpsCount] = useState(0);

    const loadMore = () => {
        setLoading(true);
    }

    const setTotalWriteUpsCountFromServer = async () => {
        const db = getFirestore();
        const q = collectionGroup(db, "WriteUps");
        let count = await getCountFromServer(q);
        setTotalWriteUpsCount(count.data().count);
    }

    useEffect(() => {
        setTotalWriteUpsCountFromServer();
    }, []);



    useEffect(() => {
        if(loading) {
            Swal.fire({
                icon : "info",
                title : "Loading More...",
                toast : true,
                showConfirmButton : false,
                position : "bottom-right",
                timer : 1000,
            });
            setLoadCount(loadCount + 6);
        }
    }, [loading]);


    const db = getFirestore();

    const getWriteUps = async () => {
        
        let q = null;
        if(loadCount > 6 && writeUps.length > 0) {
            q = query(collection(db, "WriteUps"), orderBy("createdAt", "desc"), startAfter(writeUps[writeUps.length - 1].createdAt), limit(6));
        }
        else {
            q = query(collection(db, "WriteUps"), orderBy("createdAt", "desc"), limit(6));
        }

        if( writeUps.length < totalWriteUpsCount || totalWriteUpsCount === 0 ) {
            const querySnapshot = await getDocs(q);
            const _writeUps = [];
            querySnapshot.forEach((doc) => {
                _writeUps.push({ ...doc.data(), id : doc.id });
            });
            setWriteUps([...writeUps, ..._writeUps]);
            setLoading(false);
        }
    }

    useEffect(() => {
        console.log("loadCount", loadCount);
        getWriteUps();
    }, [loadCount]);

    useEffect(() => {
        console.log("writeUps", writeUps);
    }, [writeUps]);

    

    // loadMore when user scrolls to bottom of page
   
    document.addEventListener("scroll", () => {
        if(window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            loadMore();
        }
    });


  return (
    <main>
       <nav class="navbar navbar-custom navbar-fixed-top navbar-dark" role="navigation">
        <div class="container">
          <div class="navbar-header">
           <a class="navbar-brand" href="/">Better Bonded</a>
          </div>
          <div class="" id="">
            <ul class="nav navbar-nav navbar-right">
              {/* link to contact us */}
              <li><a href="/#about">About Us</a></li>
              <li><a href="/writeUps">Blog</a></li>
              <li><a href="/contactus">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
  <div class="main">
        <section class="module bg-dark-60 blog-page-header" 
        style={{backgroundImage: `url(${coverImage})`}}
        >
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-sm-offset-3">
                <h2 class="module-title font-alt">Company Write Ups</h2>
                <div class="module-subtitle font-serif">A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</div>
              </div>
            </div>
          </div>
        </section>
        <section class="module">
          <div class="container">
            <div class="row multi-columns-row post-columns">
               
                          {
                              writeUps.map((writeUp) => {
                               return (   <div class="col-sm-6 col-md-4 col-lg-4">
                                      <div class="post" style={{
                                      }}>
                                          <div class="post-thumbnail"><img src={writeUp.thumbnail} alt="Blog-post Thumbnail" style={{
                                            maxHeight:"25em"
                                          }}></img>
                                          </div>
                                          <div class="post-header font-alt">
                                              <h2 class="post-title">{writeUp.title}</h2>
                                          </div>
                                          <div class="post-entry">
                                              <p>{writeUp.description}</p>
                                          </div>
                                          <div class="post-more"><button  onClick={() => {
                                            navigate(`/writeupdetails/`, {state: {writeUp: writeUp}})
                                          }} style={{
                                            backgroundColor: "black",
                                            color: "white",
                                            border: "none",
                                            padding: "10px 20px",
                                            textAlign: "center",
                                            textDecoration: "none",
                                            display: "inline-block",
                                            fontSize: "16px",
                                            margin: "4px 2px",
                                            cursor: "pointer",
                                            borderRadius: "5px",
                                            boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
                                            }}
                                            
                                          >Read more</button></div>
                                      </div>
                                  </div>)
                              })
              }
            </div> 
          </div>
        </section>
  
  
        <hr class="divider-d"/>
     
     
      </div>
    <footer class="footer bg-dark" style={{

    }}>
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <p class="copyright font-alt">&copy; {new Date().getFullYear()}&nbsp;<a href="index.html">Better Bonded</a>, All Rights Reserved</p>
          </div>
          {/* <div class="col-sm-6">
            <div class="footer-social-links"><a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-dribbble"></i></a><a href="#"><i class="fa fa-skype"></i></a>
            </div>
          </div> */}
        </div>
      </div>
    </footer>
</main>
  )
}

export default Index