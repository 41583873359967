import React from 'react';
import logo from './logo.svg';
import './App.css';
import './LandingPage.scss';
import LandingPage from './Views/LandingPage';
import ContactUs from './Views/ContactUs';
import WriteUps from './Views/WriteUps';
import WriteUpDetails from './Views/WriteUps/WriteUpDetails';
import { Routes, Route, Link, Navigate } from "react-router-dom";
function App() {

   

  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<LandingPage />} />
        {/* <Route path="*" component={<LandingPage/>} /> */}
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/WriteUps" element={<WriteUps />} />
        <Route path="/writeupdetails/" element={<WriteUpDetails />} />
      </Routes>
    </div>
  );
}

export default App;
