import React, { useEffect, useState } from 'react'
import {
  getFirestore, doc, collection, getDocs, query, where, limit, arrayRemove, arrayUnion,
  orderBy, deleteDoc, getCountFromServer, startAt, setDoc, getDoc, onSnapshot, addDoc
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL, listAll, deleteObject, uploadBytes, } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const Index = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const uploadData = async () => {
        Swal.fire({
            icon : "info",
            title : "Sending Message",
            text : "Please wait...",
            allowOutsideClick : false,
            allowEscapeKey : false,
            allowEnterKey : false,
            showConfirmButton : false,
            didOpen : () => {
                Swal.showLoading();
            }
        });
        
        const db = getFirestore();

        const docRef = await addDoc(collection(db, "ContactUs"), {
            name: document.getElementById("name").value,
            email: document.getElementById("email").value,
            message: message,
            createdAt : new Date(),
            timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
        });

        setName("");
        setEmail("");
        setMessage("");

        Swal.fire({
            icon : "success",
            title : "Message Sent",
            text : "Thank you your request for more information has been received. You will be contacted shortly.",
            allowOutsideClick : true,
            allowEscapeKey : true,
            allowEnterKey : true,
            showConfirmButton : true,
            //text size larger

            didOpen : () => {
                Swal.hideLoading();
            }
        });
    }

        
  return (
    <main>
        <nav class="navbar navbar-custom navbar-fixed-top navbar-dark" role="navigation">
        <div class="container">
          <div class="navbar-header">
           <a class="navbar-brand" href="/">Better Bonded</a>
          </div>
          <div class="" id="">
            <ul class="nav navbar-nav navbar-right">
              {/* link to contact us */}
              <li><a href="/#about">About Us</a></li>
              <li><a href="/writeUps">Blog</a></li>
              <li><a href="/contactus">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
        <section class="module" id="contact" style={{height:'94.2vh', marginTop:"5em"}}>
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-sm-offset-3">
                <h2 class="module-title font-alt">Contact us</h2>
                <div class="module-subtitle font-serif"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                <form id="contactForm" onSubmit={async (e) => {
                    e.preventDefault();
                    await uploadData();
                    e.target.reset();
                    }}>
                  <div class="form-group">
                    <label class="sr-only" for="name">Name</label>
                    <input class="form-control" type="text" id="name" name="name" placeholder="Your Name*"
                     required="required"  onChange={(e) => setName(e.target.value)} 
                     data-validation-required-message="Please enter your name." />
                    <p class="help-block text-danger"></p>
                  </div>
                  <div class="form-group">
                    <label class="sr-only" for="email">Email</label>
                    <input class="form-control" type="email" id="email"
                     name="email" onChange={(e) => setEmail(e.target.value)}
                      placeholder="Your Email*" required="required" data-validation-required-message="Please enter your email address." />
                    <p class="help-block text-danger"></p>
                  </div>
                  <div class="form-group">
                    <textarea class="form-control" rows="7" id="message"
                    onChange={(e) => setMessage(e.target.value)}
                    name="message" placeholder="Your Message*" required="required" data-validation-required-message="Please enter your message."></textarea>
                    <p class="help-block text-danger"></p>
                  </div>
                  <div class="text-center">
                    <button class="btn btn-block btn-round btn-d" >Submit</button>
                  </div>
                </form>
              </div>
              <div class="col-sm-4">
                {/* <div class="alt-features-item mt-0">
                  <div class="alt-features-icon"><span class="icon-megaphone"></span></div>
                  <h3 class="alt-features-title font-alt">Where to meet</h3>Titan Company<br />23 Greate Street<br />Los Angeles, 12345 LS
                </div> */}
                <div class="alt-features-item mt-xs-60">
                  <div class="alt-features-icon"><span class="icon-map"></span></div>
                  <h3 class="alt-features-title font-alt">Say Hello</h3>Email: : Info@BBondedConstruction.com<br />Phone: +1 437-855-9080
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="footer bg-dark" style={{

        }}>
          <div class="container">
            <div class="row">
              <div class="col-sm-6">
                <p class="copyright font-alt">&copy; {new Date().getFullYear()}&nbsp;<a href="index.html">Better Bonded</a>, All Rights Reserved</p>
              </div>
              {/* <div class="col-sm-6">
                <div class="footer-social-links"><a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-dribbble"></i></a><a href="#"><i class="fa fa-skype"></i></a>
                </div>
              </div> */}
            </div>
          </div>
        </footer>
    </main>
  )
}

export default Index