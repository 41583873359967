import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import {
    getFirestore, doc, collection, getDocs, query, where, limit, arrayRemove, arrayUnion,
    orderBy, deleteDoc, getCountFromServer, startAt, setDoc, getDoc, onSnapshot, startAfter, collectionGroup,
  } from "firebase/firestore";
  import { getStorage, ref, getDownloadURL, listAll, deleteObject, uploadBytes, getMetadata, } from "firebase/storage";
import Carousel from "nuka-carousel";
  const Index = () => {
    let location = useLocation();
    const [writeUp, setWriteUp] = useState(location.state.writeUp);
    const [files, setFiles] = useState([]);

    const getImagesAndVids = async () => {
        const storage = getStorage();
        const storageRef = ref(storage, `WriteUps/${writeUp.id}`);
        const list = await listAll(storageRef);
        var _files = [];
        // list.items?.forEach(async (itemRef) => {
        //     let MetaData = await getMetadata(itemRef);
        //     let url = await getDownloadURL(itemRef);
        //     let file = _files.push({
        //         url: url,
        //         name: MetaData.name,
        //         type: MetaData.contentType,
        //     });
        //     _files[file-1].component = fileImageOrVideo(_files[file-1]);
        // });
        for (let i = 0; i < list.items.length; i++) {
            let itemRef = list.items[i];
            let MetaData = await getMetadata(itemRef);
            let url = await getDownloadURL(itemRef);
            let file = _files.push({
                url: url,
                name: MetaData.name,
                type: MetaData.contentType,
            });
            _files[file-1].component = fileImageOrVideo(_files[file-1]);
        }

        console.log(_files);
          setFiles(_files);
      }

      const fileImageOrVideo = (file) => {
          const getEl = ( ) => {
              if (file.type.includes("image")) {
                  return <img src={file.url} style={{ maxHeight: "400px", maxWidth: "100%" }} alt={file.name} />
              }
              else if (file.type.includes("video")) {
                  return <video src={file.url} style={{ maxHeight: "400px", maxWidth: "100%" }} controls alt={file.name} />
              }
          }

          return (
              <div style={{ padding: "10px", margin: "10px", 
              border: "1px solid #ccc", borderRadius: "5px", 
              boxShadow: "0 0 5px #ccc", 
              backgroundColor: "#fff",
              textAlign: "center", 

              }}>
                  {getEl( )}
              </div>
          )
      }


    useEffect(() => {
        getImagesAndVids();
    }, []);


    useEffect(() => {
        console.log(writeUp);
    }, [writeUp])
    
    

  return (
    <main>
   
   <nav class="navbar navbar-custom navbar-fixed-top navbar-dark" role="navigation">
        <div class="container">
          <div class="navbar-header">
           <a class="navbar-brand" href="/">Better Bonded</a>
          </div>
          <div class="" id="">
            <ul class="nav navbar-nav navbar-right">
              {/* link to contact us */}
              <li><a href="/#about">About Us</a></li>
              <li><a href="/writeUps">Blog</a></li>
              <li><a href="/contactus">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
    <div class="main">
      <section class="module-small">
        <div class="container">
            <div class="">
              <div class="post">
                <div class="post-thumbnail">
                    <img  src={writeUp.thumbnail} style={{maxHeight: "400px", maxWidth: "100%"}}
                 alt="Blog Featured Image"/></div>
                <div class="post-header font-alt">
                  <h1 class="post-title">
                    {writeUp.title}
                  </h1>
                  <div class="post-meta">
                    <span class="post-description">
                        {writeUp.description}
                    </span>
                    <br/>
                    <span class="post-date">
                        <i class="fa fa-calendar"></i>
                        {new Date(writeUp.createdAt.seconds * 1000).toLocaleDateString()}
                    </span>
                    </div>
                </div>
                <div class="post-entry">
                    <p>
                        {writeUp.content}
                    </p>
                </div>
              </div>
               <div>
                {/* images and vids */}
                <Carousel>
                    {files.map((file) => {
                        return file.component;
                    })}
                </Carousel>
               </div>
            </div>
        </div>
      </section>
       <hr class="divider-d"/>
       <footer class="footer bg-dark" style={{

}}>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <p class="copyright font-alt">&copy; {new Date().getFullYear()}&nbsp;<a href="index.html">Better Bonded</a>, All Rights Reserved</p>
      </div>
      {/* <div class="col-sm-6">
        <div class="footer-social-links"><a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-dribbble"></i></a><a href="#"><i class="fa fa-skype"></i></a>
        </div>
      </div> */}
    </div>
  </div>
</footer>
    </div>
    <div class="scroll-up"><a href="#totop"><i class="fa fa-angle-double-up"></i></a></div>
  </main>
  )
}

export default Index